<template>
  <v-layout
    wrap
    class="qy-0"
  >
    <v-flex
      xs12
      class="pb-0"
    >
      <div ref="toolbarRef" />
    </v-flex>

    <v-flex
      xs12
      class="pt-0"
      ref="fieldEditor"
    >
      <div
        class="overflow-y-auto"
        :style="`height: 237px; border: 1px solid #dedede; border-top: 0;`"
      >
        <FieldEditor
          v-if="toolbarRef"
          :toolbar-ref="toolbarRef"
          v-model="description"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import FieldEditor from '@/components/fields/FieldEditor'

export default {
  components: {
    FieldEditor
  },

  props: {
    value: {}
  },

  data () {
    const description = toDesc(this.value)

    return {
      description,
      toolbarRef: null
    }
  },

  mounted () {
    const vm = this
    vm.$nextTick().then(() => vm.showEditor())
  },

  methods: {
    showEditor () {
      this.toolbarRef = this.$refs.toolbarRef
    }
  },

  watch: {
    description: {
      deep: true,
      handler (description) {
        const source = toDesc(description)
        const target = toDesc(this.value)
        if (source === target) return
        this.$emit('input', source)
      }
    },

    value: {
      deep: true,
      handler (value) {
        const source = toDesc(value)
        const target = toDesc(this.description)
        if (source === target) return
        this.description = source
      }
    }
  }
}

const toDesc = (val) => {
  const desc = String(val || '').trim()
  return desc === '<p></p>' ? '' : desc
}

</script>
